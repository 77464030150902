<template>
	<div class="">
		<div class="wrapper">
			<div class="left" :style="cssVars">
				<el-form :model="ruleForm" :rules="rules" size="mini" :inline="true" label-position="top" ref="ruleForm">
					<div class="required"></div><span>基本信息</span>
					<!--一行2个选项两端对齐-->
					<div style="display: flex; justify-content: space-between">
						<el-form-item label="商品分类一" prop="testSelect">
							<el-select v-model="ruleForm.testSelect"
												 filterable
												 placeholder="请选择"
												 @change="handleSelect">
								<el-option
									v-for="item in options"
									:key="item.id"
									:label="item.value"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item label="商品分类一" prop="testSelect">
							<el-select v-model="ruleForm.testSelect"
												 filterable
												 placeholder="请选择"
												 @change="handleSelect">
								<el-option
									v-for="item in options"
									:key="item.id"
									:label="item.value"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					
					</div>
					
					
					<div style="display: flex; justify-content: space-between">
						<el-form-item label="商品名称" prop="testInput">
							<el-input v-model.trim="ruleForm.testInput" autocomplete="off" clearable placeholder="testInput"></el-input>
						</el-form-item>
					
					</div>
					
					
					<div style="display: flex; justify-content: space-between">
						
						<el-form-item label="封面图" class="from-item-second">
							<UploadImage :limit="1" :url="url"></UploadImage>
						</el-form-item>
						
						
						<el-form-item label="用途说明" prop="testInput" class="from-item-second">
							<el-input v-model.trim="ruleForm.testInput"
												type="textarea"
												:rows="2"
												autocomplete="off" clearable
												placeholder="testInput"
							></el-input>
						</el-form-item>

					</div>
					
					<el-form-item label="录播图" class="from-item-second">
						<UploadImage :limit="3" :url="url"></UploadImage>
					</el-form-item>
					
					<div style="display: flex; justify-content: space-between">
						<el-form-item label="药店" prop="testSelect">
							<el-select v-model="ruleForm.testSelect"
												 filterable
												 placeholder="请选择"
												 @change="handleSelect">
								<el-option
									v-for="item in options"
									:key="item.id"
									:label="item.value"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item label="规格" prop="testInput">
							<el-input v-model.trim="ruleForm.testInput" autocomplete="off" clearable placeholder="testInput"></el-input>
						</el-form-item>
					
					</div>
					
					<div style="display: flex; justify-content: space-between">
						<el-form-item label="价格" prop="testInput">
							<el-input v-model.trim="ruleForm.testInput" autocomplete="off" clearable placeholder="testInput"></el-input>
						</el-form-item>
						
						<el-form-item label="库存" prop="testInput">
							<el-input v-model.trim="ruleForm.testInput" autocomplete="off" clearable placeholder="testInput"></el-input>
						</el-form-item>
					
					</div>
					
					<el-form-item label="是否处方药" prop="status" class="from-item-second">
						<el-radio-group v-model="ruleForm.status">
							<el-radio :label="1">启用</el-radio>
							<el-radio :label="0">禁用</el-radio>
						</el-radio-group>
					</el-form-item>
					
					
				</el-form>
			</div>
			<div class="right">
				<div class="required" style="margin-bottom: 15px"></div><span>详情介绍</span>
				
				<tinymce v-model="ruleForm.content" :height="300"/>
			</div>

		</div>
		<div class="dialog-footer">
			<el-button class="mgr-40" size="small" @click="handleCancel">取 消</el-button>
			<el-button size="small" type="primary" @click="handleConfirm('ruleForm')">确 定</el-button>
		</div>
	</div>
</template>

<script>
import UploadImage from '@/components/UploadImage/UploadImage';
import Tinymce from '@/components/Tinymce'
export default {
  name: "ProductIntroduce",
	props: {
    width: {
      type: String,
      default: '820',
    },
	},
  computed: {
    cssVars() {
      return {
        "--widthToOne" : this.width - 50 + "px",
        "--widthToTwo" : this.width / 2 - 40+ "px",
      }
    }
  },
  components: {
    UploadImage,
    Tinymce
  },
  data () {
    return {
      listLoading: false,
      url: [],
      options: [{
        value: 'test',
        id: '1'
      }],
      ruleForm: {
        testInput: '',
        testSelect: '',
        status: 1
      },
      rules: {
        testInput: [
          { required: true, message: 'testInput', trigger: 'blur' }
        ],
        testSelect: [
          { required: true, message: 'testSelect', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    handleSelect () {

    },
    handleConfirm (ruleForm) {
      this.submitForm(ruleForm)
    },
    handleCancel (e) {
      this.resetForm('ruleForm')
      this.$emit('update:isActiveDialog', false)
    },
    submitForm (formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 出发test页面更新列表
          this.$emit('getList')
          this.$emit('update:isActiveDialog', false)
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style scoped lang="scss">
.wrapper{
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	.left{
		width: 1000px;
		height: 100%;
		padding: 20px;
	}
	.right{
		width: 100%;
		height: 100%;
		padding: 20px;
	}
}



/*弹出框表单配置*/
/deep/ .el-form-item__label {
	padding-bottom: 0;
}

/deep/ .el-form-item__content {
	width: var(--widthToTwo);
}

.from-item-second {
	margin-left: 0;
}

/deep/ .from-item-third {
	.el-form-item__content {
		width: var(--widthToOne);
	}
	.el-select{
		width: var(--widthToOne) !important;
	}
}

/deep/ .el-cascader {
	width: var(--widthToTwo);
}

/deep/ .el-select {
	width: var(--widthToTwo) !important;
}

/deep/ .el-date-editor {
	width: var(--widthToTwo) !important;
}

/*select 宽度配置*/
/deep/ .el-select {
	width: 85px;
}

/*竖杠+后面文本的样式*/
.required {
	margin-top: 2px;
	display: inline-block;
	width: 4px;
	height: 14px;
	background-color: #2362FB;
	top: 20px;
}

.required+span {
	display: inline-block;
	margin-left: 4px;
	vertical-align: top;
	font-weight: bold;
	font-size: 14px;
}

.dialog-footer {
	margin-top: 20px;
	display: flex;
	justify-content: center;
}
	
	.mgr-40{
		margin-right: 40px;
	}
</style>
